.Footer{
    background-color:#ffcc74 ;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
}
.DivFooter{
    margin-right: 10px;
    display: block;
}