nav{
    width: 100%;
    height: 50px;
    display:flex;
    align-items: center;
    justify-content:center;

}
.nav{
    background-color: #aa3554;
}
button{
    margin-right: 5px;
}