.Trama{
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}
.h1Trama{
    padding-bottom: 10px;
}
.imgTrama{
    max-width: 700px;
}
@media screen and (max-width: 800px) {
    .imgTrama{
      max-width: 700px;
  }
  @media screen and (max-width: 710px) {

    .imgTrama{
      max-width: 600px;
  }
  @media screen and (max-width: 610px) {

    .imgTrama{
      max-width: 500px;
  }
  @media screen and (max-width: 510px) {

    .imgTrama{
      max-width: 400px;
  }
  @media screen and (max-width: 410px) {

    .imgTrama{
      max-width: 300px;
  }
  @media screen and (max-width: 310px) {

    .imgTrama{
      max-width: 200px;
  }
    }
    }
    }
    }
  
    }
  
    }